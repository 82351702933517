import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const IntroWrapper = styled.div`
  width: 940px;
  max-width: 100%;
  margin: 0 auto;
  padding: 60px;
  display: flex;
  align-items: center;
  @media (max-width: 1400px) {
    padding: 50px 25px;
  }
  @media (max-width: 1200px) {
    padding: 40px 25px;
  }
  @media (max-width: 990px) {
    padding: 30px 25px;
  }
  @media (max-width: 575px) {
    display: block;
    padding: 20px 25px;
    text-align: center;
  }
  @media (max-width: 380px) {
    display: block;
    padding: 30px 10px;
    text-align: center;
  }
`;

export const IntroImage = styled.div`
  width: 300px;
  max-width: 100%;    
  //height: 300px;
  padding: 30px;
  border-radius: 50%;
  margin: 0 auto;
  border: 1px solid ${({ theme }) => theme.semibackground};
  flex-shrink: 0;
  @media (max-width: 990px) {
    width: 240px;
    //height: 240px;
    padding: 25px;
    margin-bottom: 25px;
  }
  @media (max-width: 990px) {
    width: 200px;
    //height: 200px;
    padding: 20px;
    margin-bottom: 20px;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 50%;
  }

  > div {
    border-radius: 50%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.16);
    max-width: 100%;
  }
`;

// export const IntroTitle = styled.h1`
//   font-size: 30px;
//   font-weight: 400;
//   font-family: ${themeGet('fontFamily.1', "'Poppins', sans-serif")};
//   color: ${({ theme }) => theme.semiforeground};
//   margin-bottom: 20px;
//   @media (max-width: 990px) {
//     font-size: 26px;
//     margin-bottom: 15px;
//   }
//   @media (max-width: 575px) {
//     font-size: 22px;
//   }
// `;



export const IntroInfo = styled.div`
  cursor: default;
  padding: 30px;
  @media (max-width: 575px) {
    padding: 0;
  }
  //p > a:visited {
  //   color: ${themeGet('colors.primary', '#2251ff')};
  //} 
`;
