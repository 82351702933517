import PostCardMinimal from "../post-card-minimal/post-card-minimal";
import React from 'react';
import {Link} from 'gatsby';
import {StyledBlogItem} from "./blog-item.style";
import BlogMeta from "../blog-meta/blog-meta";
import {CounterFallbacks} from "../../interact";

interface BlogPostItemProps {
    node: any;
    initialLoad?: boolean;
    n_fallbacks: CounterFallbacks;
    iconSize?: string;
}

interface PostContentProps {
    title?: string;
    description?: string;
}

const PostContent: React.FunctionComponent<PostContentProps> = (
    {
        title = '',
        description = '',
    }) => {
    return (
        <div className={'post-content'}>
            <h2 className='item-title'>{title}</h2>
            <p className='item-description'>{description}</p>
            <div className='item-spacer'/>
        </div>
    )
}


const BlogPostItem: React.FunctionComponent<BlogPostItemProps> = (
    {
        node,
        initialLoad = false,
        n_fallbacks,
        iconSize='50%'
    }) => {

    return (
        <StyledBlogItem >
            <Link to={node.fields.slug}>
                <div className={'body'}>
                    <PostContent
                        title={node.frontmatter.title || node.fields.slug}
                        description={node.frontmatter.description}
                    />
                    <PostCardMinimal
                        key={node.fields.slug}
                        // title={node.frontmatter.title || node.fields.slug}
                        image={
                            node.frontmatter.cover == null
                                ? null
                                : node.frontmatter.cover
                        }
                        color={
                            node.frontmatter.color == null
                                ? `#FFFFFF`
                                : `#${node.frontmatter.color}`
                        }
                        // date={node.frontmatter.date}
                        fadeIn={!initialLoad} // Set fadeIn to true only after the initial load
                        doHover={false}
                        doLink={false}
                        iconSize={iconSize}
                    />
                </div>
                <BlogMeta
                    max_n_tags={1}
                    slug={node.fields.slug}
                    tags={node.frontmatter.tags}
                    date={node.frontmatter.date}
                    className={'item-meta'}
                    isVisit={false}
                    n_fallbacks={n_fallbacks}
                    audio={node.frontmatter.audio}
                />
            </Link>
        </StyledBlogItem>
    );
};




export default BlogPostItem