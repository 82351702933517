import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import GatsbyImage from '../../../components/gatsby-image';
import SocialProfile from '../../../components/social-profile/social-profile';

import {
    IntroWrapper,
    IntroImage,
    // IntroTitle,
    // About,
    IntroInfo,
} from './style';

import {
    FaOrcid,
    FaGithub,
    FaLinkedin,
} from 'react-icons/fa6';
import {
    BsFileEarmarkPerson
} from 'react-icons/bs'

interface IntroProps {
    about?: string;
}

const IntroText: React.FC<IntroProps> = ({about = ''}) => {
    // Define a map of substrings and their corresponding URLs
    // const replacements = ['published', 'side project', 'sports', 'travel',];

    // Replace the substrings with <Link> elements
    // const replacedText = replacements.reduce((updatedText, substring) => {
    //   const replacedSubstring = substring.replace(/\s+/g, '-'); // Replace spaces with '-'
    //   const replacementLink = `<a href="/tags/${replacedSubstring}">#${substring}</a>`;
    //   return updatedText.replace(new RegExp(substring, 'gi'), replacementLink);
    // }, about);
    // const replacedText = about;
    // Render the replaced text

    return <p dangerouslySetInnerHTML={{__html: about}}/>;
};

const Intro: React.FunctionComponent<IntroProps> = () => {
    const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/author.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      site {
        siteMetadata {
          author
          about
          socialUrls { 
            linkedin
            github
            orcid
            resume
          }
        }
      }
    }
  `);

    const {about, socialUrls} = Data.site.siteMetadata

    const AuthorImage = Data.avatar.childImageSharp.gatsbyImageData;

    const SocialLinks = [
        {
            icon: <BsFileEarmarkPerson/>,
            url: socialUrls.resume,
            tooltip: 'Tell me how you feel about LaTeX.',
        },
        {
            icon: <FaLinkedin/>,
            url: socialUrls.linkedin,
            tooltip: 'Shoot me a DM',
        },
        {
            icon: <FaGithub/>,
            url: socialUrls.github,
            tooltip: 'Check out my projects',
        },
        {
            icon: <FaOrcid/>,
            url: socialUrls.orcid,
            tooltip: 'A scholar, I like it',
        },
    ];

    return (
        <IntroWrapper>
            <IntroImage className="intro-image">
                <GatsbyImage src={AuthorImage} alt="author"/>
            </IntroImage>
            <IntroInfo>
                <IntroText about={about}/>
                <SocialProfile items={SocialLinks}/>
            </IntroInfo>
        </IntroWrapper>
    );
};

export default Intro;
