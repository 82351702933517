import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import AllPosts from '../containers/home';
import SEO from '../components/seo';
import Intro from "../containers/home/intro";

const HomePage = (props: any) => {
  const { data } = props;

  return (
    <Layout do_footy={false}>
      <SEO
        title="Home"
        description={data.site.siteMetadata.description}
        url={data.site.siteMetadata.siteUrl}
      />
      <Intro/>
      <AllPosts />
    </Layout>
  );
};

export default HomePage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
        description
      }
    }
  }
`;
