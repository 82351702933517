import * as React from 'react';
import Posts from './posts';
import {useStaticQuery, graphql} from 'gatsby';
import {
    BrowserRouter as Router,
} from "react-router-dom";


const AllPosts: React.FunctionComponent<{}> = () => {
    const Data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allSitePage(filter: { path: { eq: "/page/1" } }) {
        nodes {
          pageContext
        }
      }
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        totalCount
        edges {
          node {
            excerpt(pruneLength: 300)
            fields {
              slug
            }
            frontmatter {
              date(formatString: "DD [<span>] MMM [</span>]")
              title
              description
              tags
              color
              audio
              claps
              views
              cover {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  `);
    return (
        typeof document !== "undefined" ? (
            <Router>
                <Posts data={Data}/>
            </Router>
        ) : (
            <Posts data={Data}/>
        )
    );
};

export default AllPosts;
