import styled from "styled-components"
//
// const PersonalBlogWrapper = styled.div``
//
// export default PersonalBlogWrapper

export const FilterBar = styled.div`
  padding: 5px;
  max-width: 800px;
  margin: auto;
  align-content: center;
  span {
    margin-bottom: 2px;
    cursor: pointer;
  }
  span {
    // we give all a border, or the absence of .active would affect spacing
    border: 2px solid ${({ theme }) => theme.body};
  }
  span.active {
    border: 2px solid ${({ theme }) => theme.foreground};
    transform: scale(1.3);
  }

  //@keyframes fadeIn {
  //  0% {
  //    border-bottom: 1px solid white;
  //  }
  //  100% {
  //     border-bottom: 1px solid black;
  //  }
  //}
  //
  //.idx-cur {
  //  color: red;
  //  animation: fadeIn 2s reverse;
  //}
  //
  //.idx-new {
  //  color: green;
  //  animation: fadeIn 2s forwards;
  //}
`

// export const HoverBar = styled.div<{ from: number, to: number }>`
//   max-width: 800px;
//   margin: auto;
//   overflow: clip;
//
//   .hover-bar {
//     margin: 5px;
//     border-bottom: solid .1px #2251ff;
//     width: 16.7%;
//     transition: transform 1s ease-in-out;
//
//     @keyframes slide-over {
//       0% {
//         transform: translateX(${props => props.from * 100}%);
//       }
//       100% {
//         transform: translateX(${props => props.to * 100}%);
//       }
//     }
//     animation: slide-over .5s ease-in-out forwards;
//
//   }
// `;