import styled from 'styled-components';

export const StyledBlogItem = styled.div`
  max-width: 800px;
  min-height: 180px;
  margin: 2em auto;
  padding: 0 5px 1em 5px;
  position: relative;

  transition: transform 2s;

  //.hide {
  //  transform: translateX(800px);
  //}

  div.spacer {
    height: 3em;
  }

  > a div.body {
    color: ${({theme}) => theme.text};
    display: grid;
    grid-template-columns: auto 180px;

    .post-card {
      width: 180px;
      height: 180px;
    }

    @media (max-width: 720px) {
      grid-template-columns: auto 120px;
      .post-card {
        width: 120px;
        height: 120px;
      }
    }

    @media (max-width: 512px) {
      h2 {
        font-size: 20px;
        margin-bottom: .3em;
      }

      grid-template-columns: auto 80px;
      .post-card {
        width: 80px;
        height: 80px;
      }
    }
    @media (max-width: 360px) {
      h2 {
        font-size: 16px;
        margin-bottom: 0;
      }
    }
  }

  border-bottom: solid 1px ${({theme}) => theme.semibackground};
`;

export const StyledItemList = styled.div`
  top: -32px;
  //}
  margin: 0;
  //transition: transform 2s;
  width: 100%;

  //position: absolute;
  //background-color: blue;
  &.left {
    position: absolute;
    //background-color: red !important;
    right: 100%;
  }

  &.right {
    position: absolute;
    //background-color: green !important;
    left: 100%;
  }

  @keyframes moveLeft {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  @keyframes moveRight {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(100%);
    }
  }

  &.move-right {
    animation: moveRight .5s ease-in-out forwards;
  }

  &.move-left {
    animation: moveLeft .5s ease-in-out forwards;
  }

  //animation: moveLeft 1s ease-in-out;
`;

export const StyledItemContainer = styled.div`
  max-width: 800px;
  margin: auto;
  position: relative;
  overflow-x: clip;
`;
